import React, { useEffect, useState } from 'react';
import AdminCompanyList from '../../view/admin/AdminCompanyList';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeField,
  DELETE_COMPANY,
  GET_COMPANY_LIST,
  initializeForm,
  SET_COMPANY,
  SET_COMPANY_FORM,
} from '../../modules/auth/AuthModules';
import { CheckInfoRule } from '../../utils/UseFunc/UseFunction';
import AdminTemplate from '../../view/admin/layout/AdminTemplate';
import { SET_ALERT } from '../../modules/common/AlertModule';
import i18next from 'i18next';

const AdminController = () => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const {
    company,
    companyAuth,
    companyError,
    companyList,
    companySearch,
    companyDelete,
    pwInfo,
    pwChangeSuccess,
    checkPasswordSuccess,
    changePasswordError,
    loading,
  } = useSelector(({ auth, loading, member }) => ({
    company: auth.company,
    companyList: auth.companyList,
    companyAuth: auth.companyAuth,
    companyError: auth.companyError,
    companySearch: auth.companySearch,
    companyDelete: auth.companyDelete,
    pwInfo: auth.changePw,
    pwChangeSuccess: auth.changeAuth,
    checkPasswordSuccess: member.checkPasswordSuccess,
    changePasswordError: auth.changePasswordError,
    loading: loading.loading,
  }));

  const onChange = e => {
    const { name, value } = e.target;

    dispatch(
      changeField({
        form: 'company',
        name,
        value,
      }),
    );
  };

  const onSubmit = e => {
    const { CompId, CompEmail, CompName, CompCode } = company;
    if ([CompId, CompEmail, CompName, CompCode].includes('')) {
      alert('아이디, 업체명, 업체코드, 이메일은 필수 항목입니다.');

      return;
    }

    if(!CheckInfoRule('id', CompId)) {
      alert(i18next.t('ErrorIdType'));

      return;

    }

    if (!CheckInfoRule('email', CompEmail)) {
      alert('올바르지 않은 이메일 형식입니다.');

      return;
    }

    dispatch(SET_COMPANY(company));
  };

  const onDelete = index => {
    dispatch(
      SET_ALERT({
        type: 'Warning',
        text: i18next.t('MngAlertListNoticeDel'),
        check: true,
        submit: {
          fnc: DELETE_COMPANY(index),
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(initializeForm('companySearch'));
    dispatch(initializeForm('changePw'));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      GET_COMPANY_LIST({
        type: companySearch.type,
        codeName: companySearch.codeName,
      }),
    );
  }, [companySearch, dispatch]);

  useEffect(() => {
    if (companyAuth) {
      const { TypeCheck } = company;
      dispatch(SET_ALERT({ type: 'Success', text: `업체가 ${TypeCheck ? '수정' : '등록'}되었습니다.` }));
      closeModal();
      dispatch(initializeForm('companyAuth'));

      dispatch(
        GET_COMPANY_LIST({
          type: companySearch.type,
          codeName: companySearch.codeName,
        }),
      );
    }

    if (companyError) {
      if (companyError === 401) {
        alert('인증 비밀번호가 일치하지 않습니다');
        dispatch(initializeForm('companyError'));
      } else if (companyError === 409) {
        alert('이미 존재하는 업체 코드 입니다.');
        dispatch(initializeForm('companyError'));
      }
    }

    if (companyDelete) {
      dispatch(
        GET_COMPANY_LIST({
          type: companySearch.type,
          codeName: companySearch.codeName,
        }),
      );
      dispatch(SET_ALERT({ type: 'Success', text: i18next.t('SuccessDelete') }));
      dispatch(initializeForm('companyDelete'));
    }
    
  }, [dispatch, companyAuth, companyError, companyDelete, pwChangeSuccess, checkPasswordSuccess, changePasswordError, company]);

  const openModal = () => {
    dispatch(initializeForm('company'));

    setInfo(true);
  }

  const closeModal = () => {
    setInfo(false);
  }

  const onChangeList = e => {
    const { name, value } = e.target;

    dispatch(
      changeField({
        form: 'companySearch',
        name,
        value,
      }),
    );
  };

  const onChangeForm = e => {
    dispatch(SET_COMPANY_FORM(e));
  }

  const onPwModalClose = () => {
    setShowPw(false);
  };

  return (
    <AdminTemplate>
      <AdminCompanyList
        openModal={openModal}
        closeModal={closeModal}
        onChange={onChange}
        onChangeList={onChangeList}
        onChangeForm={onChangeForm}
        onSubmit={onSubmit}
        onDelete={onDelete}
        company={company}
        companyList={companyList}
        loading={loading}
        onCrudShow={info}
        pwInfo={pwInfo}
        onPwShow={showPw}
        onPwCheckStatus={checkPasswordSuccess}
        onPwModalClose={onPwModalClose}
      />
    </AdminTemplate>
  );
};

export default AdminController;